<template>
    <div>
      <!-- User Info: Input Fields -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form>
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex mb-1">
                <feather-icon icon="UserIcon" size="19" />
                <h4 class="mb-0 ml-50">用戶分類 -  {{ roleData.name }}</h4>
              </div>
              <b-form-group label="Role Name" label-for="name">
                <b-form-input
                  id="name"
                  v-model="roleData.name"
                  autofocus
                  trim
                  placeholder="Role Name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <!-- 權限設置 -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">權限設置</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionData"
            :fields="tableColumns"
          >
            <template #cell(module)="data">
              {{ data.value }}
            </template>
            <template #cell(name)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox
                v-model="rolePermission"
                :value="data.item.module + '.' + data.field.key"
                :checked="data.value"
              />
            </template>
          </b-table>
        </b-card>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleSubmit"
        >
          儲存權限
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleSelectAll"
        >
          選擇全部
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleCleanAll"
        >
          取消全部
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleReset"
        >
          重設
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: 'roles-list' }"
        >
          返回
        </b-button>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BOverlay,
  } from "bootstrap-vue";
  import { avatarText } from "@core/utils/filter";
  import vSelect from "vue-select";
  import { ref, onMounted, watch } from "@vue/composition-api";
  import useRolesList from "../roles-list/useRolesList";
  import store from "@/store";
  
  export default {
    components: {
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardHeader,
      BCardTitle,
      BFormCheckbox,
      vSelect,
      BOverlay,
    },
    props: {
      roleData: {
        type: Object,
        required: true,
      },
      permissionData: {
        type: Array,
        required: true,
      },
    },
    data(){
      return{
        count:0
      }
    },
    methods: {
      handleSubmit() {
        const data = {
          id: this.roleData.id,
          name: this.roleData.name,
          permission: this.rolePermission,
        };
  
        this.$swal({
          title: "確定儲存？",
          showCancelButton: true,
          confirmButtonText: "儲存",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            store
              .dispatch("app-role/updateRole", data)
              .then((response) => {
                this.loading = false;
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
                store.dispatch("app/fetchUserInfo").then((res) => {
                  let userData = res.data.user;
                  localStorage.setItem("userData", JSON.stringify(userData));
                  this.$ability.update(userData.ability);
                  this.$store.commit('SET_NAV_KEY', (count+1));
                });
  
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
      handleReset() {
        this.updateRolePermission();
      },
      handleSelectAll() {
        this.rolePermission = [];
        this.permissionData.forEach((rp) => {
            this.rolePermission.push(rp.module + ".create");
            this.rolePermission.push(rp.module + ".read");
            this.rolePermission.push(rp.module + ".update");
            this.rolePermission.push(rp.module + ".delete");
        });
      },
      handleCleanAll() {
        this.rolePermission = [];
      },
    },
    setup(props) {
      const rolePermission = ref([]);
      const loading = ref(false);
      const tableColumns = [
        { key: "name", label: "權限", stickyColumn: true },
        { key: "create" },
        { key: "read" },
        { key: "update" },
        { key: "delete" },
      ];
  
      const updateRolePermission = () => {
        rolePermission.value = [];
  
        props.permissionData.forEach((rp) => {
          if (rp.create) rolePermission.value.push(rp.module + ".create");
          if (rp.read) rolePermission.value.push(rp.module + ".read");
          if (rp.update) rolePermission.value.push(rp.module + ".update");
          if (rp.delete) rolePermission.value.push(rp.module + ".delete");
        });
      };
  
      onMounted(() => {
        updateRolePermission();
      });
  
      return {
        rolePermission,
        updateRolePermission,
        tableColumns,
        loading,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
